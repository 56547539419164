.clrbtn {
    font-size: 15px !important;
}

.clrbbl {
    font-size: 20px;
}

.clrbtn:hover{
    font-weight: 600;
}

.newClass.form-group {
    justify-content: space-between !important;
    padding-top: 15px !important;
    padding-bottom: 15px !important;
    display: flex !important;
}

.bgbtm {
    border: 0 !important;
    outline: 0 !important;
    border-radius: 0 !important;
    background: transparent !important;
    border-bottom: .5px solid #ced4da !important;
}

.Active {
    font-size: 16px !important;
    font-weight: bold;
    border: 1px solid #ced4da;
    border-radius: 5px;
}
.slctsize {
    display: block !important;
}

.nobtn {
    color: #2e4c6b !important;
    background-color: transparent !important;
    border-color: #ced4da !important;
}

.nobtn:hover {
    color: #fff !important;
    background-color: #74788d !important;
    border-color: #74788d !important;
}

/* sizes starts */
.not-def {
    font-size: 12px !important;
}
/* TODO */
/* sizes ends */

.bordered {
    border: 1px solid #ced4da;
}

#upload {
    opacity: 0;
}

#upload-label {
    position: absolute;
    top: 50%;
    left: 1rem;
    transform: translateY(-50%);
}

