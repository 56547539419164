input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.rdw-embedded-wrapper,
.rdw-emoji-wrapper,
.rdw-image-wrapper,
.rdw-link-wrapper {
  display: none;
}
.noti-icon .badge {
  left: 23px;
}
.mt-2 {
  margin-top: 20px !important;
}

.dataTables_filter,
.dataTables_paginate {
  float: right;
}
#area_table .areaBadge {
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  display: flex;
  flex-wrap: wrap;
}
.rdw-editor-main {
  border: 1px solid #eeeef5;
  height: 239px;
}
.dz-message {
  text-align: center;
  padding: 100px;
}
.fcYNFc {
  background-color: #ffffff !important;
}
.task-box {
  border: 1px solid #e1e1e6;
}
.react-datepicker-wrapper {
  width: 100% !important;
}

.ReactModal__Overlay {
  z-index: 1001 !important;
}

.fc-event .fc-content {
  padding: 5px;
  color: #fff;
}
.chat-conversation .right .conversation-list {
  margin-right: 15px;
}

.external-event {
  &:hover {
    cursor: pointer;
  }
}

.rangeslider-horizontal .rangeslider__fill {
  background-color: #7cb342 !important;
}

.custom-dashed {
  border-right: dashed !important;
}

@media screen and (max-width: "768px") {
  .custom-dashed {
    border: none !important;
  }
}
